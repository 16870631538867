//ResetPassword.js Front end
import { useRef, useState, useEffect } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "./api/axios";
import Notification from "../utils/notification";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
const RESET_URL = "https://162.240.167.99/api/resetpassword"; //Change API endpoint

const PasswordReset = () => {
  const [showNotification, setShowNotification] = useState(true);

  const [userEmail, setUserEmail] = useState("");
  const [validUserEmail, setValidUserEmail] = useState(false);
  const [userEmailFocus, setUserEmailFocus] = useState(false);
  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [confirmPwd, setConfirmPwd] = useState("");
  const [validConfirmPwd, setValidConfirmPwd] = useState(false);
  const [confirmPwdFocus, setConfirmPwdFocus] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);

  const userRef = useRef();
  const errRef = useRef();
  const emailRef = useRef();

  const togglePwdVisibility = () => {
    setShowPwd(!showPwd);
  };

  const toggleConfirmPwdVisibility = () => {
    setShowConfirmPwd(!showConfirmPwd);
  };

  useEffect(() => {
    setValidUserEmail(EMAIL_REGEX.test(userEmail));
  }, [userEmail]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
  }, [pwd]);

  useEffect(() => {
    setValidConfirmPwd(confirmPwd === pwd);
  }, [confirmPwd, pwd]);

  useEffect(() => {
    setErrMsg("");
  }, [userEmail, pwd, confirmPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const v1 = USER_REGEX.test(user);
    const v5 = PWD_REGEX.test(pwd);
    const v6 = EMAIL_REGEX.test(userEmail);
    // const v4 = MCNUMBER_REGEX.test(mcnumber);

    if (!v5 || !v6) {
      setErrMsg("Invalid Entry");
      return;
    }

    try {
      const response = await axios.post(
        RESET_URL,
        JSON.stringify({ userEmail, pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log(response?.data);
      console.log(response?.accessToken);
      console.log(JSON.stringify(response));
      setSuccess(true);
      setUserEmail("");
      setPwd("");
      setConfirmPwd("");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed");
      }
      console.error("Registration Failed:", err);
      errRef.current.focus();
    }
  };

  const handleNotificationClose = () => {
    setShowNotification(false);
  };

  return (
    <>
      <div className="register-page">
        {showNotification && (
          <Notification
            message={Notification}
            onClose={handleNotificationClose}
          />
        )}
      </div>

      <br />
      <h1 className="pageTitles">Reset Password</h1>
      {success ? (
        <section className="registersection">
          <h1>Success!</h1>
          <p>
            <a href="#">Sign In</a>
          </p>
        </section>
      ) : (
        <section className="registersection">
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <form onSubmit={handleSubmit}>
            {/* Username */}

            {/* Email Address */}
            <label htmlFor="userEmail">
              Email Address:
              <FontAwesomeIcon
                icon={faCheck}
                className={validUserEmail ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validUserEmail || !userEmail ? "hide" : "invalid"}
              />
            </label>
            <input
              type="email"
              id="userEmail"
              ref={emailRef}
              autoComplete="off"
              onChange={(e) => setUserEmail(e.target.value)}
              value={userEmail}
              required
              aria-invalid={validUserEmail ? "false" : "true"}
              aria-describedby="useremailnote"
              onFocus={() => setUserEmailFocus(true)}
              onBlur={() => setUserEmailFocus(false)}
            />
            <p
              id="useremailnote"
              className={
                userEmailFocus && userEmail && !validUserEmail
                  ? "instructions"
                  : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              Valid email address
              <br />
            </p>

            {/* MC Number */}

            {/* Password */}
            <label htmlFor="password">
              New Password:
              <FontAwesomeIcon
                icon={faCheck}
                className={validPwd ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validPwd || !pwd ? "hide" : "invalid"}
              />
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                flexGrow: 1,
                paddingBottom: "1rem",
                fontFamily: "Nunito, sans-serif",
                fontSize: "22px",
                padding: "0.25rem",
                borderRadius: "0.5rem",
                appearance: "none",
                marginBottom: "-9%",
              }}
            >
              <input
                type={showPwd ? "text" : "password"}
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                aria-invalid={validPwd ? "false" : "true"}
                aria-describedby="pwdnote"
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
              />
              <i
                onClick={togglePwdVisibility}
                style={{
                  position: "relative",
                  right: "-340px",
                  top: "-25%",
                  transform: "translateY(-50%)",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  margin: 0,
                }}
              >
                <FontAwesomeIcon icon={showPwd ? faEye : faEyeSlash} />
              </i>
            </div>
            <p
              id="pwdnote"
              className={pwdFocus && !validPwd ? "instructions" : "offscreen"}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              8 to 24 characters.
              <br />
              Must include uppercase and lowercase letters, a number, and a
              special character.
              <br />
              Allowed special characters:{" "}
              <span aria-label="exclamation mark">!</span>{" "}
              <span aria-label="at symbol">@</span>{" "}
              <span aria-label="hashtag">#</span>{" "}
              <span aria-label="dollar sign">$</span>{" "}
              <span aria-label="percent">%</span>
            </p>

            {/* Confirm Password */}
            <label htmlFor="confirm_pwd">
              Confirm New Password:
              <FontAwesomeIcon
                icon={faCheck}
                className={validConfirmPwd && confirmPwd ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validConfirmPwd || !confirmPwd ? "hide" : "invalid"}
              />
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                flexGrow: 1,
                paddingBottom: "1rem",
                fontFamily: "Nunito, sans-serif",
                fontSize: "22px",
                padding: "0.25rem",
                borderRadius: "0.5rem",
                appearance: "none",
                marginTop: "0",
              }}
            >
              <input
                type={showConfirmPwd ? "text" : "password"}
                id="confirm_pwd"
                onChange={(e) => setConfirmPwd(e.target.value)}
                value={confirmPwd}
                required
                aria-invalid={validConfirmPwd ? "false" : "true"}
                aria-describedby="confirmnote"
                onFocus={() => setConfirmPwdFocus(true)}
                onBlur={() => setConfirmPwdFocus(false)}
              />
              <i
                onClick={toggleConfirmPwdVisibility}
                style={{
                  position: "relative",
                  right: "-340px",
                  top: "-25%",
                  transform: "translateY(-50%)",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  margin: 0,
                }}
              >
                <FontAwesomeIcon icon={showConfirmPwd ? faEye : faEyeSlash} />
              </i>
            </div>
            <p
              id="confirmnote"
              className={
                confirmPwdFocus && !validConfirmPwd
                  ? "instructions"
                  : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              Must match the first password input field.
            </p>
            <br />
            <button disabled={!validUserEmail || !validPwd || !validConfirmPwd}>
              Reset Password{" "}
            </button>
          </form>
          {/* 
          <p>
            Already registered?
            <br />
            <span className="line">
     
              <a href="#">Sign In</a>
            </span>
          </p> */}
        </section>
      )}
      <br></br>
      <br></br>

      <br></br>

      <br></br>

      <br></br>
      <br></br>

      <br></br>

      <br></br>
    </>
  );
};

export default PasswordReset;
