// mainpage.js
import React, { useState } from "react";
import ParentComponent from "./searchComponent.js"; // Importing the SearchComponent
import PieChartDisplay from "./chart.js"; // Importing the PieChartDisplay component
import SearchComponent from "./searchComponent.js";

function MainPage() {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  return (
    <div>
      <h1>Main Page</h1>
      <SearchComponent onSearch={handleSearch} />{" "}
      {/* Rendering the SearchComponent and passing handleSearch */}
      <PieChartDisplay
        searchTerm={searchTerm}
        handleSearch={handleSearch}
      />{" "}
      {/* Rendering the PieChartDisplay component and passing handleSearch */}
    </div>
  );
}

export default MainPage;
