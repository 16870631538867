import React, { useState } from "react";
import "../styles.css";

// import Popper from "popper.js";

function Overview() {
  return (
    <div>
      <br></br>
      <h1 className="pageTitles">Overview</h1>
      {/* style={{ textalign: "justify" }} */}
      <p className="overview">
        Welcome to TheBroker411.com – Your Ultimate Resource for Freight Broker
        Reviews! At TheBroker411.com, we understand the importance of making
        informed decisions when it comes to choosing a freight broker. Our
        platform provides an exclusive members-only experience, offering
        valuable insights into brokerages through user reviews. This overview
        will guide you on how to efficiently search for reviews using either the
        MC number or the broker's business name after logging into the
        members-only page.<br></br> <br></br>
        <b>Step 1:</b> Log In to Your Members-Only Account To access the
        comprehensive database of freight broker reviews, you need to log in to
        your members-only account on TheBroker411.com. If you haven't registered
        yet, sign up to unlock the power of real user experiences and opinions
        within the logistics industry. <br></br>
        <br></br>
        <b>Step 2:</b> Navigate to the Search Page Once logged in, navigate to
        the search page to begin your review exploration. Our user-friendly
        interface ensures a seamless experience for both new and experienced
        members.
        <br></br> <br></br>
        <b>Step 3:</b> Search by MC Number If you have the MC number of the
        broker you are interested in, simply enter it into the designated search
        bar on the page. Click 'Search,' and our system will retrieve all
        relevant reviews associated with the specified MC number. <br></br>
        <br></br>
        <b>Step 4:</b> Search by Business Name Alternatively, if you prefer to
        search by the broker's business name, enter the name into the search bar
        and click 'Search.' Our platform will then display a list of brokers
        matching the entered business name, allowing you to select the specific
        one you are interested in.<br></br> <br></br>
        <b>Step 5:</b> Explore Reviews Once you've executed your search, you
        will be presented with a list of reviews related to the MC number or
        business name you entered. Dive into each review to gain valuable
        insights into the broker's performance, reliability, and overall
        customer satisfaction. <br></br> <br></br>
        <b>Step 6:</b> Contribute Your Review At TheBroker411.com, we believe in
        community-driven insights. If you've had experiences with a freight
        broker, share your thoughts by contributing your review. Your feedback
        helps fellow members make informed decisions in the dynamic world of
        logistics. Empower yourself with TheBroker411.com – where transparency
        meets the freight industry. Our platform is dedicated to facilitating an
        informed and efficient decision-making process for all your freight
        brokerage needs. Sign in, search, and discover the truth in reviews at
        TheBroker411.com – your trusted partner in the freight industry!
      </p>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default Overview;
