// PrivacyPolicy.js
import React, { useState } from "react";

const TermsAndConditions = ({ onAccept }) => {
  return (
    <div className="legal-container">
      <h1 className="legal-header">Terms and Conditions</h1>
      <p className="legal-text">
        Welcome to TheBroker411.com By accessing or using TheBroker411.com (the
        "Website"), you agree to be bound by these Terms and Conditions (the
        "Terms"). If you do not agree with any part of these Terms, you must not
        use the Website. <h2>1. Membership and Subscription</h2>{" "}
        <strong>1.1 Membership Eligibility:</strong> To become a member of
        TheBroker411.com, you must be at least 18 years old and provide accurate
        and complete registration information.{" "}
        <strong>1.2 Subscription Fees:</strong> Members are required to pay a
        monthly subscription fee to access and use the services provided by
        TheBroker411.com. The current subscription fees are listed on the
        Website and are subject to change at our discretion.{" "}
        <strong>1.3 Payment Terms:</strong> Subscription fees are billed on a
        monthly basis and must be paid in advance. By subscribing, you authorize
        us to charge your provided payment method for the subscription fees on a
        recurring monthly basis. <strong>1.4 Cancellation:</strong> You may
        cancel your membership at any time by following the cancellation
        procedures provided on the Website. Your access to the Website and its
        services will continue until the end of the current billing period.
        <h2>2. Use of the Website</h2> <strong>2.1 Member Conduct:</strong> As a
        member, you agree to use the Website for lawful purposes only. You must
        not post or transmit through the Website any material that is
        fraudulent, defamatory, obscene, or otherwise objectionable.{" "}
        <strong>2.2 Review Posting:</strong> Members may leave reviews of
        freight brokers on the Website. You agree to post truthful and accurate
        reviews based on your genuine experiences. TheBroker411.com reserves the
        right to remove or edit any reviews that violate these Terms or
        applicable laws. <h2>3. Intellectual Property</h2>{" "}
        <strong>3.1 Ownership:</strong> All content on the Website, including
        text, graphics, logos, and software, is the property of TheBroker411.com
        or its content suppliers and is protected by intellectual property laws.{" "}
        <strong>3.2 License:</strong> TheBroker411.com grants you a limited,
        non-exclusive, non-transferable license to access and use the Website
        for personal and non-commercial purposes.{" "}
        <h2>4. Disclaimers and Limitation of Liability</h2>{" "}
        <strong>4.1 No Warranty:</strong>
        TheBroker411.com provides the Website on an "as is" and "as available"
        basis without any warranties of any kind, either express or implied. 4.2
        **Limitation of Liability**: To the fullest extent permitted by law,
        TheBroker411.com shall not be liable for any indirect, incidental,
        special, or consequential damages arising out of or in connection with
        your use of the Website. <h2>5. Governing Law</h2> These Terms shall be
        governed by and construed in accordance with the laws of the State of
        California, without regard to its conflict of laws principles. Any legal
        action or proceeding arising under these Terms shall be brought
        exclusively in the federal or state courts located in California{" "}
        <h2>6. Changes to the Terms</h2> TheBroker411.com reserves the right to
        modify these Terms at any time. We will notify you of any changes by
        posting the new Terms on the Website. Your continued use of the Website
        after such changes constitutes your acceptance of the new Terms.{" "}
        <h2>7. Questions or Concerns</h2>
        Contact Us If you have any questions about these Terms, please contact
        us at info@thebroker411.com
      </p>
      <br></br> <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default TermsAndConditions;
