// PrivacyPolicy.js
import React, { useState } from "react";

const PrivacyPolicy = ({ onAccept }) => {
  return (
    <div className="legal-container">
      <h1 className="legal-header">Privacy Policy</h1>
      <p className="legal-text">
        TheBroker411.com ("we", "our", or "us") is committed to protecting your
        privacy. This Privacy Policy outlines how we collect, use, disclose, and
        protect your personal information when you use our website,
        TheBroker411.com (the "Website"). By accessing or using the Website, you
        agree to the terms of this Privacy Policy. <br></br>
        <br></br> <h2>1. Information We Collect:</h2>
        <strong>1.1 Personal Information:</strong> When you register for a
        membership, subscribe to our services, or interact with the Website, we
        may collect personal information such as your name, email address,
        payment information, and any other information you voluntarily provide.
        1.2 <strong>Usage Data:</strong> We may automatically collect
        information about your interactions with the Website, including your IP
        address, browser type, device information, pages visited, and the dates
        and times of your visits. 1.3
        <strong>Cookies and Tracking Technologies:</strong> We use cookies and
        similar tracking technologies to enhance your experience on the Website.
        Cookies are small data files stored on your device. You can control the
        use of cookies through your browser settings.{" "}
        <h2>2. How We Use Your Information</h2>{" "}
        <strong>2.1 Provide Services:</strong> We use your personal information
        to provide and manage your membership, process your payments, and
        deliver the services you have requested.{" "}
        <strong>2.2 Communication:</strong> We may use your personal information
        to communicate with you about your membership, respond to your
        inquiries, and send you updates or promotional materials.{" "}
        <strong>2.3 Improvement:</strong> We use the information we collect to
        understand how users interact with the Website, improve our services,
        and develop new features. <strong>2.4 Legal Obligations:</strong> We may
        use your information to comply with applicable legal obligations,
        enforce our Terms and Conditions, and protect our rights and the rights
        of our users. <h2>3. Disclosure of Your Information.</h2>{" "}
        <strong>3.1 Service Providers:</strong> We may share your personal
        information with third-party service providers who assist us in
        operating the Website, processing payments, and delivering our services.
        These service providers are bound by contractual obligations to keep
        your information confidential and use it only for the purposes for which
        we disclose it to them. 3.2 **Legal Requirements**: We may disclose your
        personal information if required to do so by law or in response to valid
        requests by public authorities (e.g., a court or government agency).{" "}
        <strong>3.3 Business Transfer:</strong> In the event of a merger,
        acquisition, or sale of all or a portion of our assets, your personal
        information may be transferred to the acquiring entity.{" "}
        <h2>4. Data Security</h2>
        We implement reasonable security measures to protect your personal
        information from unauthorized access, use, alteration, and disclosure.
        However, no method of transmission over the Internet or electronic
        storage is 100% secure, and we cannot guarantee absolute security.{" "}
        <h2>5. Your Rights</h2> <strong>5.1 Access and Correction:</strong> You
        have the right to access and correct your personal information. You can
        update your account information through your member profile or by
        contacting us. <strong>5.2 Deletion:</strong> You have the right to
        request the deletion of your personal information, subject to certain
        legal exceptions. <strong>5.3 Opt-Out:</strong> You can opt-out of
        receiving promotional communications from us by following the
        unsubscribe instructions provided in those communications or by
        contacting us. <h2>6. Children's Privacy</h2> The Website is not
        intended for use by individuals under the age of 18. We do not knowingly
        collect personal information from children under 18. If we become aware
        that we have inadvertently received personal information from a user
        under the age of 18, we will delete such information from our records.{" "}
        <h2>7. Changes to This Privacy Policy</h2> We may update this Privacy
        Policy from time to time. We will notify you of any changes by posting
        the new Privacy Policy on the Website and updating the effective date.
        Your continued use of the Website after any changes indicates your
        acceptance of the updated Privacy Policy.{" "}
        <h2>8. Questions or Concerns</h2> Contact Us If you have any questions
        or concerns about this Privacy Policy or our privacy practices, please
        contact us at: info@thebroker411.com 9. No Sale of Personal Data We do
        not sell, trade, or otherwise transfer to outside parties your personal
        information.
      </p>
      <br></br>
      <br></br>

      <br></br>

      <br></br>
      <br></br>
    </div>
  );
};

export default PrivacyPolicy;
