import React, { useState } from "react";

import { SocialIcon } from "react-social-icons";
import ibexLogo from "../images/ibexlogo.png";

import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";

import "bootstrap/dist/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

const ibexLogoSrc = ibexLogo;
const ibexLogoStyle = {
  width: "130px",
  height: "auto",
  marginBottom: "-12px",
};

const NewFooter = () => {
  return (
    <footer>
      <MDBContainer>
        <MDBRow>
          <MDBCol
            className="footer-col-Ibex"
            size="md"
            style={{ height: "40px" }}
          >
            <img
              src={ibexLogoSrc}
              alt="Ibex Technologies Logo"
              style={ibexLogoStyle}
            />
            <br></br>
          </MDBCol>

          <MDBCol
            className="footer-col-text"
            size="md"
            style={{ height: "40px" }}
          >
            <p>
              Built by Ibex Technologies for The Broker 411. <br></br>
              <a className="ibexLink" href="https://www.ibextechnologies.io">
                www.ibextechnologies.io
              </a>
              <br></br>
              Copyright © 2024 The Broker 411 - All Rights Reserved.
            </p>
          </MDBCol>

          <MDBCol className="footer-col" size="md" style={{ height: "40px" }}>
            <SocialIcon
              className="socialIcons"
              network="facebook"
              bgColor="#5258cf"
              url="https://www.facebook.com/profile.php?id=61556371824602&mibextid=LQQJ4d"
              style={{
                marginRight: "6px",

                width: "45px",
                height: "45px",
                marginLeft: "155px",
              }}
            />
            <SocialIcon
              network="instagram"
              bgColor="#e33f5f"
              url="https://www.instagram.com/thebroker411?igsh=ZjBmb3M2ZHJ1MHly&utm_source=qr
          "
              style={{
                marginRight: "6px",
                width: "45px",
                height: "45px",
              }}
            />
            <SocialIcon
              network="tiktok"
              bgColor="#000000"
              url="https://www.tiktok.com/@the.broker.411?_t=8jcYfcRsWsM&_r=1
          "
              style={{
                marginRight: "6px",

                width: "45px",
                height: "45px",
              }}
            />
            <SocialIcon
              network="youtube"
              bgColor="#ff3333"
              url="https://your-facebook-url"
              style={{
                marginRight: "6px",

                width: "45px",
                height: "45px",
              }}
            />
            <SocialIcon
              network="linkedin"
              bgColor="#2596be"
              url="https://www.linkedin.com/company/100498458/admin/feed/posts"
              style={{
                marginRight: "6px",
                width: "45px",
                height: "45px",
              }}
            />{" "}
            <br></br>
            <span></span>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </footer>
  );
};

export default NewFooter;
