import React, { useState, useEffect } from "react";

function DisplayDataPage({ searchQuery }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); //NEW

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page (reviews)

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("http://thebroker411.com/api/Brokers"); //Endpoint
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false); //NEW
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const filterData = () => {
    if (!searchQuery) return data;
    return data.filter((broker) => {
      const nameMatch = broker.name.toLowerCase() === searchQuery.toLowerCase();
      const numberMatch = String(broker.number) === searchQuery;
      return nameMatch || numberMatch;
    });
  };

  const filteredData = filterData();

  return (
    <div
      className="ScrollableContainer"
      style={{ maxHeight: "500px", overflowY: "auto" }}
    >
      {searchQuery ? (
        filteredData.map((broker, index) => (
          <div key={index}>
            <div className="ReviewCard">
              <h3>
                <strong>Broker Name:</strong> {broker.name}
              </h3>
              <p>
                <strong>MC Number:</strong> {broker.number}
              </p>
            </div>
            <BrokerReviews
              reviews={broker.reviews}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
            />
            <BrokerReviewPagination
              totalReviews={broker.reviews.length}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              paginate={paginate}
            />
          </div>
        ))
      ) : (
        <p className="ReviewCard">No data to display</p>
      )}
    </div>
  );
}

const BrokerReviews = ({ reviews, currentPage, itemsPerPage }) => {
  const indexOfLastReview = currentPage * itemsPerPage;
  const indexOfFirstReview = indexOfLastReview - itemsPerPage;
  const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);

  return (
    <ul>
      {currentReviews.map((review, idx) => (
        <li key={idx} className="review-item">
          <strong className="review-subheader">Carrier:</strong>{" "}
          <p className="review-text">{review.username}</p>
          <strong className="review-subheader">Review:</strong>{" "}
          <p className="review-text">{review.review}</p>
          <strong className="review-subheader">Date Submitted:</strong>{" "}
          <p className="review-text">{review.dateStamp}</p>
          <hr></hr>
        </li>
      ))}
    </ul>
  );
};

const BrokerReviewPagination = ({
  totalReviews,
  currentPage,
  itemsPerPage,
  paginate,
}) => {
  const pageNumbers = Math.ceil(totalReviews / itemsPerPage);

  return (
    <nav>
      <ul className="pagination">
        {[...Array(pageNumbers).keys()].map((number) => (
          <li
            key={number + 1}
            className={`page-item ${
              currentPage === number + 1 ? "active" : ""
            }`}
          >
            <button onClick={() => paginate(number + 1)} className="page-link">
              {number + 1}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default DisplayDataPage;
