import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import b411Logo from "../images/b411logo.png";
import Notification from "../utils/notification";

const Navbarsx2 = () => {
  const navigate = useNavigate();
  const [navDropdownOpen, setNavDropdownOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showNotification, setShowNotification] = useState(true);

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleProfileClick = () => {
    navigate("/profile");
    setNavDropdownOpen(false);
  };

  const handleNotificationClose = () => {
    setShowNotification(false);
  };

  return (
    <div>
      <nav className="hrefStyle2">
        <a className="hrefStyle2" href="http://thebroker411.com">
          <img
            src={b411Logo}
            alt="The Broker 411 Logo"
            style={{
              width: "200px",
              height: "auto",
              display: "flex",
              margin: "auto",
              backgroundColor: "#ffffff",
            }}
          />
        </a>
      </nav>

      {showNotification && (
        <Notification
          message="Thebroker411.com is currently undergoing maintenance for improved security and performance. Please check back soon."
          onClose={handleNotificationClose}
        />
      )}

      <Navbar
        expand="lg"
        className={`secondnavbar ${isCollapsed ? "collapsed" : ""}`}
      >
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={toggleNavbar}
        />
        <Navbar.Collapse>
          <Nav className="mr-auto">
            <Link
              to="/"
              className={`nav-link ${
                window.location.pathname === "/" && "selected"
              }`}
            >
              HOME
            </Link>
            <Link
              to="/AboutUs"
              className={`nav-link ${
                window.location.pathname === "/AboutUs" && "selected"
              }`}
            >
              ABOUT US
            </Link>
            <Link
              to="/SignUp"
              className={`nav-link ${
                window.location.pathname === "/SignUp" && "selected"
              }`}
            >
              SIGN UP NOW
            </Link>
            <Link
              to="/Reviews"
              className={`nav-link ${
                window.location.pathname === "/ReviewPage" && "selected"
              }`}
            >
              LEAVE A REVIEW
            </Link>
            <Link
              to="/IndexOfReviews"
              className={`nav-link ${
                window.location.pathname === "/Review" && "selected"
              }`}
            >
              INDEX OF REVIEWS
            </Link>
            <Link
              to="/ContactUs"
              className={`nav-link ${
                window.location.pathname === "/ContactUs" && "selected"
              }`}
            >
              CONTACT US
            </Link>
          </Nav>
          <Nav className="secondnavbar">
            <NavDropdown
              title="RESOURCES"
              id="secondnavbar"
              open={navDropdownOpen}
              onToggle={() => setNavDropdownOpen(!navDropdownOpen)}
            >
              <NavDropdown.Item href="/Overview">OVERVIEW</NavDropdown.Item>
              <NavDropdown.Item href="/Glossary">GLOSSARY</NavDropdown.Item>
              <NavDropdown.Item href="/FAQ">
                FREQUENTLY ASKED QUESTIONS
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title="ACCOUNT"
              id="secondnavbar"
              open={navDropdownOpen}
              onToggle={() => setNavDropdownOpen(!navDropdownOpen)}
            >
              <NavDropdown.Item href="/Login">SIGN IN</NavDropdown.Item>
              <NavDropdown.Item href="/SignUp">CREATE ACCOUNT</NavDropdown.Item>
              <NavDropdown.Item href="/profile">MY ACCOUNT</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Navbarsx2;
