import React from "react";
import { faSearch, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Volvo from "../images/volvo.jpg";

import "../components/home.css";

const VolvoTruck = () => {
  return (
    <div>
      <img src={Volvo} width="100%" height="50%" alt="Volvo Truck" />
    </div>
  );
};

function AboutUs() {
  return (
    <div>
      <br />
      <h1 className="pageTitles">About Us</h1>
      <p className="overview">
        Folks, let me tell ya about <strong>Thebroker411.com.</strong> It's been
        a dream come true for us, a dream nurtured over the long miles we've
        logged on the road. Finally, thanks to some recent turns in our journey,
        we've made it happen. Our goal with this site is simple: to create a
        space where all you hardworking members of the transportation industry
        can get the real scoop on freight brokers straight from the carriers
        themselves. In our thirty years of truckin', we've had the pleasure of
        teaming up with some top-notch broker partners. And let me tell ya, if a
        site like <strong>Thebroker411.com</strong> had been around back then,
        we would've been singing their praises loud and clear. But, sadly, we've
        also had our fair share of not-so-smooth sailing with some brokers.
        Those were the times we wished we had a platform like this to share our
        experiences, to give our fellow carriers the heads-up they needed to
        make savvy business choices. So, here we are, bringin' this site to life
        for you. Our mission is to tailor it to fit your needs as we keep on
        growin' and improvin'. We want this place to be yours, a go-to spot
        where you can find the real deal on brokers and make informed decisions.
        We're all ears and open to suggestions, so don't hesitate to drop us a
        line via the good ol'
        <span></span> <span></span>
        <a href="/ContactUs">'Contact Us'</a> form. We're excited to hear from
        ya and ready to make this site the best it can be for all of us in the
        truckin' community. Let's roll down this road together, keepin' each
        other in the know every step of the way.
      </p>
      <VolvoTruck />
      <div className="tagline taglinebackground">
        <h2>The Broker411.com ~ by truckers, for truckers</h2>
        <br></br> <br></br>
      </div>
    </div>
  );
}

export default AboutUs;
