import React from "react";
import classes from "../fourOfour.module.css";

function FourOfour() {
  return (
    <div>
      <body class={classes.body}>
        <div class={classes.errorContainer}>
          <h1 class={classes.h1}> 404 </h1>
          <p class={classes.p}>
            Oops! This one is not a 411, but a 404. <br></br> The page you're
            looking for cannot be found.
          </p>
          <a class={classes.a} href="https://www.thebroker411.com/">
            Go Back to Home
          </a>
        </div>
      </body>
    </div>
  );
}

export default FourOfour;
