//seachComponent.js
import React, { useState, useEffect } from "react";
import PieChartDisplay from "./chart";

function SearchComponent() {
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("thebroker411/api/Brokers"); //Change API Endpoint
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search by Broker Name or MC Number"
        value={searchTerm}
        onChange={handleSearch}
      />
      {data && <PieChartDisplay searchTerm={searchTerm} data={data} />}{" "}
      {/* Render PieChartDisplay only if data is available */}
    </div>
  );
}

export default SearchComponent;
