//ProtectedRoutes.js

import React from "react";
import { Navigate } from "react-router-dom";

import { useUser } from "../context/UserContext";

const ProtectedRoute = ({ children }) => {
  const { user } = useUser();

  if (!user || user.status !== "active") {
    return <Navigate to="/signInRequired" />;
  }

  return children;
};

const UserRoutes = ({ children }) => {
  const { user } = useUser();

  if (!user || user.status !== "inactive") {
    return <Navigate to="/subscriptionRequired" />;
  }

  return children;
};

export { ProtectedRoute, UserRoutes };

// export default ProtectedRoute;
