// ... other imports

function ContactForm() {
  return (
    <div>
      <div className="mainHome">
        <div className="col3">{/* Remove if not needed */}</div>
        <div className="col2">
          <br /> <br />
          <h1 className="pageTitles">Contact Us</h1>
          <div className="email-container">
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <p className="emailAddress">
              <b>For general information please email us at:</b> <br />
              <a href="mailto:info@thebroker411.com">info@thebroker411.com</a>
            </p>
            <br></br>
            <br></br>

            <p className="emailAddress">
              <b>For issues with your account please email us at:</b> <br />
              <a href="mailto:accounts@thebroker411.com">
                accounts@thebroker411.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
