// import React from "react";

// const Notification = ({ message, onClose }) => {
//   return (
//     <div className="notification">
//       <h2 className="alert">Alert</h2>
//       <span>{message}</span>
//       <side>Click here to close this message</side>
//       <br />
//       <div className="close-button" onClick={onClose}>
//         <side>&times;</side>
//         <br></br>
//       </div>
//     </div>
//   );
// };

// export default Notification;
// Notification.js
import React from "react";
import "../styles.css"; // Ensure this import points to your CSS file

const Notification = ({ message, onClose }) => {
  return (
    <div className="notification-container">
      <div className="notification">
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Notification;
