import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles.css";
import video from "../videos/video.mp4";
import containerYard from "../images/containers.jpeg";
import roadLights from "../images/trailinglights.jpeg";
import wv209 from "../images/wv209.jpeg";
import blackandwhitetruck from "../images/blackandwhite.jpg";
import Notification from "../utils/notification";
import tickmark from "../images/blackticklogo.jpg";
import { Container, Row, Col, Navbar, Nav, Button } from "react-bootstrap";
import FootrDesign from "../utils/footer";

const VideoPlayer = () => {
  const [showNotification, setShowNotification] = useState(false);

  const handleClick = () => {
    setShowNotification(true);
    setTimeout(() => {
      window.location.href = "https://thebroker411.com/SignUp";
    }, 3000);
  };

  const handleNotificationClose = () => {
    setShowNotification(false);
  };

  return (
    <>
      <div className="mainPage">
        <video src={video} className="video-bg" autoPlay loop muted></video>
        <div className="content">
          <h1>
            Review A <br />
            Broker
          </h1>
          <button className="button" onClick={handleClick}>
            CLICK <br /> TO <br />
            SIGN UP
          </button>
        </div>
      </div>

      {/* Notification */}
      {showNotification && (
        <Notification
          message="We are working on our back end and will be back soon."
          onClose={handleNotificationClose}
        />
      )}
    </>
  );
};

const YardImage = () => {
  return (
    <div className="containerImage">
      <h1 className="containersOverlay">
        $30 monthly <span></span>
        <button
          className="payNowBttn"
          onClick={() =>
            (window.location.href = "https://buy.stripe.com/4gw3cG64Rdbuf6M5km")
          }
        >
          PAY NOW
        </button>
      </h1>
      <img src={containerYard} width="100%" height="25%" />
    </div>
  );
};

const BlackandWhiteBanner = () => {
  return (
    <div>
      <img src={blackandwhitetruck} width="100%" height="25%" />
    </div>
  );
};

const TickMarkPic = () => {
  return (
    <div>
      <img src={tickmark} width="100%" height="11%" size="50%" />
    </div>
  );
};

const Adimage = () => {
  return (
    <div>
      <img src={wv209} width="100%" height="50%" />
    </div>
  );
};

function Home() {
  const currentPath = window.location.pathname;

  const isPageSelected = (pathname) => {
    return currentPath === pathname;
  };

  return (
    <div>
      <VideoPlayer />
      <YardImage />
      <p className="para">
        <br />
        Why use this site?
        <br />
        Thebroker411.com was developed to offer carriers a user-friendly
        platform for evaluating their experiences with their freight broker
        counterparts. This platform enables carriers to make informed business
        decisions by leveraging reviews submitted by their peers. We welcome all
        types of reviews, particularly those that showcase recent experiences.
        Our goal is to provide an unfiltered and unedited repository of carrier
        experiences that can aid in informed decision-making. We invite carriers
        to share their feedback on their broker's performance today, with the
        assurance that all reviews are the authentic and exclusive experience of
        the carrier poster.
        <br />
        <br />
        <br />
      </p>
      <div className="advertiseBanner">
        <p>
          Advertise with Us <br />
          Email us today <span></span>
          <span></span>
          <a href="mailto:info@thebroker411.com">info@thebroker411.com</a>
        </p>
      </div>
      <Adimage />
      <BlackandWhiteBanner />
    </div>
  );
}

export default Home;
