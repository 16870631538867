import React, { useEffect, useState } from "react";
import "../styles.css";

function IndexOfReviewPage() {
  const [backendData, setBackendData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchNumber, setSearchNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    // let apiUrl = "/Brokers";
    let apiUrl = "http://localhost:8080/Brokers";

    // If searchName is present, append it to the apiUrl
    if (searchName) {
      apiUrl += `?name=${searchName}`;
    }

    // If searchNumber is present, append it to the apiUrl
    if (searchNumber) {
      apiUrl += searchName
        ? `&number=${searchNumber}`
        : `?number=${searchNumber}`;
    }

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      setBackendData(data.Brokers);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
    console.log();
  };

  useEffect(() => {
    fetchData();
  }, [searchName, searchNumber]); // Fetch data whenever searchName or searchNumber changes

  return (
    <div>
      <div>
        <label htmlFor="nameSearch">Search by Name:</label> <br></br>
        <input
          type="text"
          id="nameSearch"
          placeholder="Enter broker name"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="numberSearch">Search by Number:</label>
        <br></br>
        <input
          type="text"
          id="numberSearch"
          placeholder="Enter broker number"
          value={searchNumber}
          onChange={(e) => setSearchNumber(e.target.value)}
        />
      </div>
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {/* Render the fetched data in your component */}
      {backendData?.map((broker, i) => (
        <div key={i}>
          <h2>{broker.name}</h2>
          <h3>MC Number: {broker.number}</h3>
          <h3>Reviews:</h3>

          <ul>
            {broker.reviews &&
              broker.reviews.map((review, j) => (
                <li key={j}>
                  <h4>Review by: {review.username}</h4>
                  <p>Review Date: {review.datestamp}</p>
                  <p>Review: {review.review}</p>
                </li>
              ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default IndexOfReviewPage;
