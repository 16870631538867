// //login.js
// import { useRef, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useAuth } from "../context/AuthContext";

// import "../styles.css";
// import axios from "./api/axios";

// const LOGIN_URL = "http://162.240.167.99/api/login";

// const Login = () => {
//   const { setUser } = useAuth();

//   const userRef = useRef();
//   const errRef = useRef();

//   const [userEmail, setUserEmail] = useState("");
//   const [pwd, setPwd] = useState("");
//   const [errMsg, setErrMsg] = useState("");
//   const [success, setSuccess] = useState(false);
//   const [showPwd, setShowPwd] = useState(false);

//   const navigate = useNavigate();

//   useEffect(() => {
//     userRef.current.focus();
//   }, []);

//   useEffect(() => {
//     setErrMsg("");
//   }, [userEmail, pwd]);

//   const togglePwdVisibility = () => {
//     setShowPwd(!showPwd);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await axios.post(
//         LOGIN_URL,
//         JSON.stringify({ userEmail, pwd }),
//         {
//           headers: { "Content-Type": "application/json" },
//           withCredentials: true,
//         }
//       );
//       console.log("Response data:", response.data); // Add this line for debugging

//       if (response.data.token) {
//         localStorage.setItem("user", response.data.token);
//         console.log("User set in context:", response.data.user);

//         setUser(response.data.user);
//         setSuccess(true);

//         navigate("/profile");
//       } else {
//         setErrMsg("No token in response");
//       }
//     } catch (error) {
//       console.error("Login failed", error);
//       if (!error.response) {
//         setErrMsg("No Server Response");
//       } else if (error.response.status === 400) {
//         setErrMsg("Missing Username or Password");
//       } else if (error.response.status === 401) {
//         setErrMsg("Unauthorized");
//       } else {
//         setErrMsg("Login Failed");
//       }
//       errRef.current.focus();
//     }
//   };

//   return (
//     <>
//       <h1 className="pageTitles">Sign In</h1>

//       <div className="register-page">
//         {success ? (
//           <section>
//             <h1>You are logged in!</h1>
//             <p>
//               <a href="/">Go to Home</a>
//             </p>
//           </section>
//         ) : (
//           <section className="registersection">
//             <p
//               ref={errRef}
//               className={errMsg ? "errmsg" : "offscreen"}
//               aria-live="assertive"
//             >
//               {errMsg}
//             </p>

//             <form onSubmit={handleSubmit}>
//               <label htmlFor="email">Email:</label>
//               <input
//                 type="email"
//                 id="email"
//                 ref={userRef}
//                 autoComplete="off"
//                 onChange={(e) => setUserEmail(e.target.value)}
//                 value={userEmail}
//                 required
//               />

//               <label htmlFor="password">Password:</label>
//               <input
//                 type={showPwd ? "text" : "password"}
//                 id="password"
//                 onChange={(e) => setPwd(e.target.value)}
//                 value={pwd}
//                 required
//               />

//               <i
//                 onClick={togglePwdVisibility}
//                 style={{
//                   position: "relative",
//                   left: "185px",
//                   top: "-25px",
//                   transform: "translateY(-125%)",
//                   cursor: "pointer",
//                   margin: 0,
//                 }}
//               >
//                 <FontAwesomeIcon icon={showPwd ? faEye : faEyeSlash} />
//               </i>
//               <button type="submit">Sign In</button>
//             </form>
//             <p>
//               Need an Account?
//               <br />
//               <span className="line">
//                 <a href="/Register">Sign Up</a>
//               </span>
//             </p>
//             <p>
//               Forgot Password?
//               <br />
//               <span className="line">
//                 <a href="/Register">Request OTP</a>
//               </span>
//             </p>
//           </section>
//         )}
//       </div>
//     </>
//   );
// };

// export default Login;

import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUser } from "../context/UserContext";
import "../styles.css";
import axios from "./api/axios";
// import { useAuth } from "../context/AuthContext";

const LOGIN_URL = "https://162.240.167.99/api/login";

const Login = () => {
  const { setUser } = useUser();

  const userRef = useRef();
  const errRef = useRef();

  const [userEmail, setUserEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  // const { login } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [userEmail, pwd]);

  const togglePwdVisibility = () => {
    setShowPwd(!showPwd);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ userEmail, pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      console.log("Response data:", response.data); // Add this line for debugging

      if (response.data.token) {
        localStorage.setItem("user", response.data.token);
        console.log("User set in context:", response.data.user);

        setUser(response.data.user);
        setSuccess(true);

        navigate("/profile");
      } else {
        setErrMsg("No token in response");
      }
    } catch (error) {
      console.error("Login failed", error);
      if (!error.response) {
        setErrMsg("No Server Response");
      } else if (error.response.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (error.response.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <>
      <h1 className="pageTitles">Sign In</h1>

      <div className="register-page">
        {success ? (
          <section>
            <h1>You are logged in!</h1>
            <p>
              <a href="/">Go to Home</a>
            </p>
          </section>
        ) : (
          <section className="registersection">
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>

            <form onSubmit={handleSubmit}>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUserEmail(e.target.value)}
                value={userEmail}
                required
              />

              <label htmlFor="password">Password:</label>
              <input
                type={showPwd ? "text" : "password"}
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
              />

              <i
                onClick={togglePwdVisibility}
                style={{
                  position: "relative",
                  left: "185px",
                  top: "-3px",
                  transform: "translateY(-125%)",
                  cursor: "pointer",
                  margin: 0,
                }}
              >
                <FontAwesomeIcon icon={showPwd ? faEye : faEyeSlash} />
              </i>
              <button type="submit">Sign In</button>
            </form>
            <p>
              Need an Account?
              <br />
              <span className="line">
                <a href="/Register">Sign Up</a>
              </span>
            </p>
          </section>
        )}
      </div>
    </>
  );
};

export default Login;
