import React from "react";
import classes from "../fourOfour.module.css";

function SubscriptionRequired() {
  return (
    <div>
      <body class={classes.body}>
        <div class={classes.errorContainer}>
          <h1 class={classes.h1}> Subsription Required. </h1>
          <p class={classes.p}>
            Unauthorized <br></br> Please subscribe to view this page.
          </p>
          <a class={classes.a} href="https://www.thebroker411.com/">
            Go Back to Home
          </a>
        </div>
      </body>
    </div>
  );
}

export default SubscriptionRequired;
