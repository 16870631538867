import axios from "axios";

export default axios.create({
  baseURL: "https://thebroker411/api/",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// import axios from "axios";

// const axios = axios.create({
//   baseURL: "http://162.240.167.99/api/",
//   headers: {
//     "Content-Type": "application/json",
//     Accept: "application/json",
//   },
//   withCredentials: true,
// });

// export default axios;
