import React, { useState } from "react";
import DisplayDataPage from "./displayData";
import PieChartDisplay from "./chart";

const CombinedComponents = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div
      className="ScrollableContainer"
      style={{ maxHeight: "500px", overflowY: "auto" }}
    >
      <br />
      <h1 className="pageTitles">Index Of Reviews</h1>
      <i className="centered-text">
        Search in field below using MC Number or Broker Name. <br></br>
        Please don't include the MC prefix when searching by MC Number.
      </i>
      <div className="SearchInputContainer">
        <input
          type="text"
          placeholder="Broker Search"
          value={searchQuery}
          onChange={handleSearchChange}
          className="SearchInput"
        />
      </div>
      <div></div>
      <div className="SideBySideContainer">
        <div className="DisplayDataContainer" style={{ flex: 1 }}>
          <DisplayDataPage searchQuery={searchQuery} />
        </div>
        <div className="PieChartContainer" style={{ flex: 1 }}>
          <PieChartDisplay searchQuery={searchQuery} />
        </div>
      </div>
    </div>
  );
};

export default CombinedComponents;
